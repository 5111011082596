import type { SeamlyConfig } from '@apps/chat/shared/multilabel/src/ChatProvider';
import type { Locale } from '@common/application';
import type { TransformFunctions } from '@sitecore/common';

export const businessUnit = 'be';
export const countryCode = 'BE';
export const label = 'eneco';
export const name = 'be-eneco-main';
export const uuid = 'LePAAt';
export const seamlyConfig: SeamlyConfig = {
  defaultNamespace: 'consumer-be',
  instance: 'eneco-be',
  defaultLocale: 'nl-informal',
  defaultTopic: 'on_start',
  userLocale: '',
};

export const transformFunctions: TransformFunctions = {
  transformSite: ({ item, site }) => {
    let inferredSite = site ?? '';

    if (
      item?.startsWith('/nl/kmo') ||
      item?.startsWith('/kmo') ||
      item?.startsWith('/fr/pme') ||
      item?.startsWith('/pme')
    ) {
      inferredSite = 'website_eneco_be_kmo';
    } else if (
      item?.startsWith('/nl/business') ||
      item?.startsWith('/business') ||
      item?.startsWith('/fr/entreprises') ||
      item?.startsWith('/entreprises') ||
      item?.startsWith('/en/business')
    ) {
      inferredSite = 'website_eneco_be_business';
    }

    return inferredSite;
  },

  transformLocale: ({ locale, item }) => {
    let inferredLocale: string = locale ?? 'nl-BE';

    if (item?.startsWith('/nl/')) {
      inferredLocale = 'nl';
    } else if (item?.startsWith('/de/')) {
      inferredLocale = 'de';
    } else if (item?.startsWith('/fr/')) {
      inferredLocale = 'fr';
    } else if (item?.startsWith('/en/')) {
      inferredLocale = 'en';
    }

    // Handle the workaround for Next.js locale detection
    if (locale?.startsWith('de')) {
      inferredLocale = 'de';
    } else if (locale?.startsWith('fr')) {
      inferredLocale = 'fr';
    } else if (locale?.startsWith('nl')) {
      inferredLocale = 'nl';
    } else if (locale?.startsWith('en')) {
      inferredLocale = 'en';
    }

    return inferredLocale as Locale;
  },

  transformItem: ({ item }) => {
    let inferredItem = item ?? '';

    if (item?.startsWith('/nl/')) {
      inferredItem = item.replace(/^\/nl/, '');
    } else if (item?.startsWith('/de/')) {
      inferredItem = item.replace(/^\/de/, '');
    } else if (item?.startsWith('/fr/')) {
      inferredItem = item.replace(/^\/fr/, '');
    } else if (item?.startsWith('/en/')) {
      inferredItem = item.replace(/^\/en/, '');
    }

    return inferredItem;
  },
};